.head-menu {
    display: flex;
    align-items: center;

    &__item {
        padding: 0 10px;

        &-link {
            display: block;
            font-size: 16px;
            font-family: map-get($ff, m);
            align-items: center;
            color: $body-color;
            padding: 48px 0;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom-color: $primary;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        border-left: none;
        padding-left: 0;
        margin-left: 0;
        border-bottom: 1px solid $hr-color;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &__item {
            width: 100%;
            padding: 0;

            &-link {
                margin: 0;
                font-size: 20px;
                color: $body-color;
                padding: 10px 0;

                &:hover, &.is-active {
                }
            }
        }
    }

    @include media-breakpoint-only(xl) {
        padding-left: 0;
        margin-left: 0;
        border-left: none;
    }
}
