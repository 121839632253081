@function strip-units($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }

    $base: 16;
    @if not unitless($base) {
        $base: strip-units($base);
    }
    @return ($pxval / $base) * 1rem;
}

@function em($pixels, $context: 16px) {
    @return ($pixels/$context) * 1em;
}

@function rfsSpacing($value) {
    @if $rfs-status {
        @return rfs-fluid-value($value);
    } @else {
        @return $value;
    }
}

@mixin getFonts($name, $sizes) {
    $fontpath: "#{$font-path}#{$name}/";

    @each $size in $sizes {
        @font-face {
            font-family: '#{$name}-#{$size}';
            src: url('#{$fontpath}#{$name}-#{$size}.woff2') format('woff2'),
            url('#{$fontpath}#{$name}-#{$size}.woff') format('woff');
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
        }

        .ff-#{$name}-#{$size} {
            font-family: '#{$name}-#{$size}';
        }
    }
}

@mixin article() {
    @extend .clearfix;

    .paragraph {
        font-size: 16px;
        line-height: 1.6;
    }

    h1 {
    }

    p {
        margin: 0 0 $paragraph-margin-bottom 0;
        @extend .paragraph;
    }

    b {
        font-weight: 400;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: $paragraph-margin-bottom;

        li {
            @extend .paragraph;
            margin-bottom: 10px;

            &:before {
                content: '';
                display: block;
                float: left;
                width: 8px;
                height: 8px;
                background: #fff;
                margin: 8px 15px 0 0;
                border-radius: 10px;
                border: 2px solid $primary;
            }
        }
    }

    img {
        margin-bottom: 40px;
        max-width: 100%;

        &[style*=left] {
            margin-right: 50px;
        }

        &[style*=right] {
            margin-left: 50px;
        }
    }

    @include media-breakpoint-down(md) {
        img {
            width: 100%;
        }
    }
}

@mixin shortText($font-size, $line-height, $rows) {
    height: round($font-size * $line-height * $rows);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
}

@mixin centerImage($width: '', $height: '') {
    @if $width {
        width: $width;
    }
    @if $height {
        height: $height;
    }
    object-fit: cover;
    object-position: center;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin headroom() {
    &.headroom {
        will-change: transform;
        transition: transform 200ms linear;

        &--pinned {
            transform: translateY(0%);
            top: 0;
        }

        &--unpinned {
            transform: translateY(-100%);
            top: -10px;
        }
    }
}
