.site-head {
}

.head-block {
    @include media-breakpoint-down(xl) {
        &--left {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 20px;
            border-bottom: 1px solid $hr-color;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &--right {
            position: fixed;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 300px;
            overflow-y: scroll;
            padding: 20px;
            padding-top: map-get($header-height, md) + 20;
            background: #fff;
            box-shadow: 5px 0 16px 0 rgba($black, 0.05);
            transform: translate(calc(-100% - 20px), 0);
            opacity: 0;
            transition: all ease 0.3s;

            &.is-active {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }
}

.head-logo {
    display: flex;
    align-items: center;

    padding-right: rfs-fluid-value(30px);
    margin-right: rfs-fluid-value(25px);
    border-right: 1px solid $hr-color;

    &__link {
    }

    &__image {
    }

    @include media-breakpoint-down(xl) {
        padding-right: 0;
        margin-right: 0;
        border: none;
        &__image {
        }
    }
}
