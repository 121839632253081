.foot-copyright {
	&__image {
	}

	&__text {
		font-size: 14px;
		line-height: 1.2;

		color: rgba($dark, 0.3);
	}
}

