.job-list {
    &__item {
        opacity: 0.5;
        transition: $transition-base;

        &:hover {
            box-shadow: $box-shadow-lg;
            opacity: 1;
        }
    }

    @include media-breakpoint-down(xl) {
        &__item {
            opacity: 1;
        }
    }
}