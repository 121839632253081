.page-head {
    position: relative;
    margin: rfs-fluid-value(40px) 0;

    &__title {
        font-family: map-get($ff, m);
        font-size: 40px;
        line-height: 1.3;
        margin-bottom: 0;
    }
}
