[class*="display-"] {
	font-family: map-get($ff, m);
}

/* Font Family */
@include getFonts($ff-name, $ff-sizes);

/* Text Truncate */
@for $i from 1 through 4 {
	@for $k from 14 through 20 {
		.text-truncate-#{$i}-#{$k} {
			@include shortText($k, $line-height-base, $i);
		}
	}
}

/* Rotate */
@each $rotate, $var in $rotates {
	.rotate-#{$rotate} {
		transform: rotate($var);
	}
}

/* Opacity */
@for $i from 0 through 9 {
	.opacity-#{$i} {
		opacity: #{$i / 10} !important;
	}
}