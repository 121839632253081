.foot-address {
	display: flex;
	flex-direction: column;

	a , span {
		font-size: 16px;
		line-height: 1.3;

		color: $dark;

		& + a , & + span {
			margin-top: 10px;
		}
	}
}
