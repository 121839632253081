$utilities: map-merge(
        $utilities,
        (
            'font-weight': map-merge(map-get($utilities, 'font-weight'), (
                values: map-merge(map-get(map-get($utilities, 'font-weight'), 'values'), (
                    medium: $font-weight-medium
                ))
            )),
            'cursor': (
                property: cursor,
                class: cursor,
                responsive: true,
                values: auto pointer grab,
            ),
            'min-width': (
                property: min-width,
                class: minw,
                responsive: true,
                values: $position-values
            ),
            'max-width': (
                property: max-width,
                class: mw,
                responsive: true,
                values: $position-values
            ),
            'position': map-merge(map-get($utilities, 'position'), (
                responsive: true
            )),
            'height': map-merge(map-get($utilities, 'height'), (
                responsive: true,
            )),
            'width': map-merge(map-get($utilities, 'width'), (
                responsive: true,
                values: (
                    0: 0,
                    10: 10%,
                    20: 20%,
                    25: 25%,
                    30: 30%,
                    40: 40%,
                    50: 50%,
                    60: 60%,
                    70: 70%,
                    75: 75%,
                    80: 80%,
                    90: 90%,
                    100: 100%,
                    auto: auto
                )
            )),
            'border-color': map-merge(map-get($utilities, 'border-color'), (
                local-vars: (
                    "border-opacity": 1
                ),
                values: map-loop($utilities-bg, rgba-css-var, "$key", "border")
            )),
            'border-opacity': (
                css-var: true,
                class: border-opacity,
                values: (
                    10: .1,
                    20: .20,
                    25: .25,
                    30: .30,
                    40: .40,
                    50: .5,
                    60: .60,
                    70: .70,
                    75: .75,
                    80: .80,
                    90: .90,
                    100: 1
                )
            ),
            'bg-opacity': (
                css-var: true,
                class: bg-opacity,
                values: (
                    10: .1,
                    20: .20,
                    25: .25,
                    30: .30,
                    40: .40,
                    50: .5,
                    60: .60,
                    70: .70,
                    75: .75,
                    80: .80,
                    90: .90,
                    100: 1
                )
            ),
            'background-color': map-merge(map-get($utilities, 'background-color'), (
                state: hover
            )),
            'text-color': (
                property: color,
                class: text,
                state: hover,
                values: $theme-colors
            ),
            'rotate': (
                property: transform,
                class: rotate,
                values: (
                    '0': 0,
                    '45': rotate(45deg),
                    '90': rotate(90deg),
                    '135': rotate(135deg),
                    '180': rotate(180deg),
                    '225': rotate(225deg),
                    '270': rotate(270deg),
                )
            ),
            'font-size': (
                property: font-size,
                class: fz,
                values: $px10to32,
                responsive: true
            ),
            'border-radius': (
                property: border-radius,
                class: bdrs,
                values: $px10to32,
                responsive: true
            ),
            'line-height': (
                property: line-height,
                class: lh,
                values: map-merge($px10to32, (
                    0.8: 1,
                    0.9: 1,
                    1: 1,
                    1.2: 1.2,
                    1.3: 1.3
                )),
                responsive: true
            ),
            'shadow': map-merge(map-get($utilities, 'shadow'), (
                state: hover
            ))
        )
);
