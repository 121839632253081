.package-list {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            font-size: 16px;
            font-family: map-get($ff, b);

            > * {
                display: flex;
                align-items: center;

                &:before {
                    @include fi($fi-circle-check);
                    font-size: 30px;
                    margin-right: 20px;
                }
            }

            b , strong {
                font-weight: 400;

                &:before {
                    color: $teal;
                }
            }

            u {
                text-decoration: none;

                &:before {
                    color: $gray-400;
                }
            }
        }
    }
}
