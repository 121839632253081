.head-language {
    &__item {
        font-family: map-get($ff, m);
        font-size: 16px;
        padding: 10px;
        color: $body-color;

        &:hover , &.active {
            background: $gray-100;
            color: $primary;
        }
    }

}