.head-dropdown-menu {
	position: absolute;
	top: map-get($header-height, lg);
	left: 0;
	width: 100%;
	background: $gray-100;

	display: none;
	z-index: -1;
	opacity: 0;
	transform: translate(0, 30px);
	transition: all ease 0.3s;
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);

	&.is-active {
		z-index: 9;
		display: block;

		&.is-show {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	&__inner {
		padding-top: 60px;
	}
}
