.foot-menu {
    $rootName: foot-menu;
    margin: 0 -24px;

    &__item {
        padding: 0 24px;
        margin: 5px 0;

        &-link {
            font-size: 16px;
            font-family: map-get($ff, m);
            color: $body-color;
        }
    }

    &__children {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        &-link {
            font-size: 16px;
            font-family: map-get($ff, r);
            color: rgba($body-color, 0.5);
        }
    }

    &--horizontal {
        display: flex;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }


    @include media-breakpoint-down(md) {
        &__item {
            margin-bottom: 16px;
        }

    }
}
