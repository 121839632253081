.home-service-item {
    max-width: 300px;
    padding-right: rfsSpacing($box-group-padding) $i;
    padding-left: 30px;

    &:hover {
        border-left: 5px solid $primary;
        background: rgba(#fff, 0.2);
    }

    @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;
        padding: 30px;
    }
}