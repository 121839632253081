@font-face {
  font-family: "icon-font";
  src: url('../fonts/icon-font/icon-font.eot');
  src: url('../fonts/icon-font/icon-font.eot?#iefix') format('eot'),
    url('../fonts/icon-font/icon-font.woff2') format('woff2'),
    url('../fonts/icon-font/icon-font.woff') format('woff'),
    url('../fonts/icon-font/icon-font.ttf') format('truetype'),
    url('../fonts/icon-font/icon-font.svg#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin fi($icon) {
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $icon;

}

.fi {
  display: block;
}

.fi.fi:before {
  display: block;
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi.fi-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fi.fi-2x { font-size: 2em; }
.fi.fi-3x { font-size: 3em; }
.fi.fi-4x { font-size: 4em; }
.fi.fi-5x { font-size: 5em; }
.fi.fi-fw {
  width: 1.2857142857142858em;
  text-align: center;
}


$fi-angle-down: "\EA01";
.fi.fi-angle-down:before { display: block; content: "\EA01" }

$fi-angle-left: "\EA02";
.fi.fi-angle-left:before { display: block; content: "\EA02" }

$fi-angle-right: "\EA03";
.fi.fi-angle-right:before { display: block; content: "\EA03" }

$fi-angle-up: "\EA04";
.fi.fi-angle-up:before { display: block; content: "\EA04" }

$fi-arrow-down: "\EA05";
.fi.fi-arrow-down:before { display: block; content: "\EA05" }

$fi-arrow-left: "\EA06";
.fi.fi-arrow-left:before { display: block; content: "\EA06" }

$fi-arrow-right: "\EA07";
.fi.fi-arrow-right:before { display: block; content: "\EA07" }

$fi-arrow-up: "\EA08";
.fi.fi-arrow-up:before { display: block; content: "\EA08" }

$fi-basket: "\EA09";
.fi.fi-basket:before { display: block; content: "\EA09" }

$fi-caret-down: "\EA0A";
.fi.fi-caret-down:before { display: block; content: "\EA0A" }

$fi-caret-left: "\EA0B";
.fi.fi-caret-left:before { display: block; content: "\EA0B" }

$fi-caret-right: "\EA0C";
.fi.fi-caret-right:before { display: block; content: "\EA0C" }

$fi-caret-up: "\EA0D";
.fi.fi-caret-up:before { display: block; content: "\EA0D" }

$fi-check: "\EA0E";
.fi.fi-check:before { display: block; content: "\EA0E" }

$fi-circle-check: "\EA0F";
.fi.fi-circle-check:before { display: block; content: "\EA0F" }

$fi-circle-close: "\EA10";
.fi.fi-circle-close:before { display: block; content: "\EA10" }

$fi-circle-minus: "\EA11";
.fi.fi-circle-minus:before { display: block; content: "\EA11" }

$fi-circle-plus: "\EA12";
.fi.fi-circle-plus:before { display: block; content: "\EA12" }

$fi-circle-warning: "\EA13";
.fi.fi-circle-warning:before { display: block; content: "\EA13" }

$fi-close: "\EA14";
.fi.fi-close:before { display: block; content: "\EA14" }

$fi-download: "\EA15";
.fi.fi-download:before { display: block; content: "\EA15" }

$fi-facebook: "\EA16";
.fi.fi-facebook:before { display: block; content: "\EA16" }

$fi-instagram: "\EA17";
.fi.fi-instagram:before { display: block; content: "\EA17" }

$fi-linkedin: "\EA18";
.fi.fi-linkedin:before { display: block; content: "\EA18" }

$fi-mail: "\EA19";
.fi.fi-mail:before { display: block; content: "\EA19" }

$fi-map: "\EA1A";
.fi.fi-map:before { display: block; content: "\EA1A" }

$fi-minus: "\EA1B";
.fi.fi-minus:before { display: block; content: "\EA1B" }

$fi-pdf: "\EA1C";
.fi.fi-pdf:before { display: block; content: "\EA1C" }

$fi-phone: "\EA1D";
.fi.fi-phone:before { display: block; content: "\EA1D" }

$fi-pin: "\EA1E";
.fi.fi-pin:before { display: block; content: "\EA1E" }

$fi-pinterest: "\EA1F";
.fi.fi-pinterest:before { display: block; content: "\EA1F" }

$fi-plus: "\EA20";
.fi.fi-plus:before { display: block; content: "\EA20" }

$fi-search: "\EA21";
.fi.fi-search:before { display: block; content: "\EA21" }

$fi-star: "\EA22";
.fi.fi-star:before { display: block; content: "\EA22" }

$fi-time: "\EA23";
.fi.fi-time:before { display: block; content: "\EA23" }

$fi-trash: "\EA24";
.fi.fi-trash:before { display: block; content: "\EA24" }

$fi-twitter: "\EA25";
.fi.fi-twitter:before { display: block; content: "\EA25" }

$fi-view: "\EA26";
.fi.fi-view:before { display: block; content: "\EA26" }

$fi-whatsapp: "\EA27";
.fi.fi-whatsapp:before { display: block; content: "\EA27" }

$fi-youtube: "\EA28";
.fi.fi-youtube:before { display: block; content: "\EA28" }

