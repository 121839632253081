.page-breadcrumb {

    &__inner {
        border-top: 1px solid $hr-color;
        border-bottom: 1px solid $hr-color;
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__link {
        font-size: 14px;
        font-family: map-get($ff, r);
        line-height: 1.4;
        color: $gray-300;

        &:after {
            @include fi($fi-angle-right);
            font-size: 8px;
            display: inline-block;
            margin: 0 8px;
        }

        &:last-child {
            color: darken($gray-300, 10%);

            &:after {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        &__inner {
            border-top: none;
        }

    }

    @include media-breakpoint-down(md) {
        &__list {
            flex-wrap: nowrap;
            overflow-x: scroll;
            white-space: nowrap;
            padding: 5px 0;
        }
    }
}
