.home-promo {

    &__item {
        position: relative;
        z-index: 1;
        padding: rfsSpacing($box-group-padding);
        height: 100vh;
        max-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @each $breakpoint, $size in $header-height {
            @include media-breakpoint-up($breakpoint) {
                height: calc(100vh - #{$size} - 30px);
            }
        }

        &.is-selected {
            z-index: 2;
        }
    }

    &__bg-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__title {
        opacity: 0;
        transform: translate(0, -150px);
        transition: $transition-base;

        .is-selected & {
            opacity: 1;
            transform: translate(0);
        }
    }

    &__btn {
        opacity: 0;
        transform: translate(0, 150px);
        transition: $transition-base;

        .is-selected & {
            opacity: 1;
            transform: translate(0);
        }
    }

    .flickity-page-dots {
        @include make-container();

        @each $breakpoint, $size in $container-max-widths {
            @include media-breakpoint-up($breakpoint) {
                width: $size;
            }
        }

        position: absolute;
        bottom: 0;
        left: 0;
        justify-content: flex-start;
        padding: rfsSpacing($box-group-padding);

        .dot {
            width: 10px;
            height: 10px;
            background: rgba(#fff, 0.5);

            &.is-selected {
                background: #fff;
            }
        }
    }


    @include media-breakpoint-down(md) {
        &__item {

        }

    }
}
