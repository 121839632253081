.box-group {
	$className: box-group;


	&__title {
	}

	&__sub-title {
	}

	&__description {
	}

	&__btn {
	}

	&__bg-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
	}

	&__inner {
		background: $gray-100;

		&--home-service {
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				max-width: 450px;
				@include gradient-x(transparent, #000);
				width: 100%;
				height: 100%;
			}

			@include media-breakpoint-down(md) {
				&:before {
					@include gradient-y(transparent, #000);

				}
			}
		}

	}


}
