.faq {
    $rootName: faq;

    &__item {
        margin-bottom: 15px;
        border: 1px solid $hr-color $i;
        border-radius: 15px $i;

        &.active {
            box-shadow: $box-shadow-lg;
        }
    }

    &__head {
    }

    &__body {
        &-inner {
            padding: 20px 35px;
            padding-top: 0;
        }
    }

    &__btn {
        font-size: 18px;
        font-family: map-get($ff, m);
        padding: 30px 35px;
        box-shadow: none $i;
        background: none $i;
        color: $body-color $i;

        &:after {
            border: 1px solid $hr-color;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: none $i;
            @include fi($fi-angle-down);
            font-size: 20px;
        }
    }

    &--sm {
        .#{$rootName}__btn {
            padding: 10px 30px;
        }

        .#{$rootName}__body {
            &-inner {
                padding: 10px 30px;
            }
        }
    }

    &--opacity {
        .#{$rootName}__item {
            opacity: 0.5;
            transition: $transition-base;
            transform: scale(0.98);

            &.active {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__btn, &__body-inner {
            padding: 10px 20px;
        }
    }
}
