.head-contact {
	$className: head-contact;

	&__label {
	}

	&__value {
	}

	&__icon {
	}

}
