.sidebar-menu {
    $padding: 18px;

    border: 1px solid $hr-color;
    border-radius: 10px;
    box-shadow: $box-shadow-lg;


    &__item {
        &-link {
            display: block;
            font-size: 18px;
            font-family: map-get($ff, m);
            color: $dark;
            padding: $padding ($padding * 2);
        }

        & + & {
            border-top: 1px solid $hr-color;
        }
    }

    &__children {
        display: flex;
        flex-direction: column;
        padding: $padding ($padding * 2);
        margin-top: ($padding / 2) * -1;

        &-link {
            font-size: 16px;
            font-family: map-get($ff, m);
            color: $gray-500;
            padding: ($padding / 4) 0;

            &:hover, &.active {
                color: $primary;
            }
        }
    }

}
