/* ===== Helper */
$img-path: '../images/';
$font-path: '../fonts/';
$i: !important;

/* ===== Sizes */
$rfs-status: true;
$box-group-padding: 50px;
$header-height: (
	xs: 65px,
	md: 80px,
	lg: 100px,
	xl: 120px,
	xxl: 120px,
);
$rotates: (
	'0': 0,
	'45': 45deg,
	'90': 90deg,
	'135': 135deg,
	'180': 180deg,
	'225': 225deg,
	'270': 270deg,
);

$px10to32: (
	'0': 0,
) !default;
@for $i from 10 through 64 {
	$px10to32: map-merge($px10to32, (
			$i: #{$i}px
	))
}

/* ===== Fonts */
$ff-name: 'satoshi';
$ff-sizes: light, regular, medium, bold, xbold;
$ff: (
	xl: $ff-name + '-xlight',
	l: $ff-name + '-light',
	r: $ff-name + '-regular',
	m: $ff-name + '-medium',
	b: $ff-name + '-bold',
	xb: $ff-name + '-xbold'
);

/* ===== Colors */

/* ===== Social Media Colors */
$facebook-color: #3b5999;
$facebook-messenger-color: #0084ff;
$twitter-color: #55acee;
$instagram-color: #e4405f;
$google-plus-color: #dd4b39;
$whatsapp-color: #25D366;
$linkedin-color: #0077B5;
$gmail-color: #c71610;
$youtube-color: #cd201f;
$sms-color: #80e27e;

/* ===== Bootstrap Spacers */;
$spacer: 1rem !default;
$spacers: (
		0: 0,
		1: $spacer * 0.25,
		2: $spacer * 0.50,
		3: $spacer * 0.75,
		4: $spacer,
		5: $spacer * 1.25,
		6: $spacer * 1.50,
		7: $spacer * 1.75,
		8: $spacer * 2.00,
		9: $spacer * 2.25,
		10: $spacer * 2.50,
		11: $spacer * 2.75,
		12: $spacer * 3.00,
		13: $spacer * 3.25,
		14: $spacer * 3.50,
		15: $spacer * 3.75,
		16: $spacer * 4.00,
		17: $spacer * 4.25,
		18: $spacer * 4.50,
		19: $spacer * 4.75,
		20: $spacer * 5.00,
		21: $spacer * 5.50,
		22: $spacer * 6.00,
		23: $spacer * 6.50,
		24: $spacer * 7.00,
		25: $spacer * 7.50,
		26: $spacer * 8.00,
		27: $spacer * 8.50,
		28: $spacer * 9.00,
		29: $spacer * 9.50,
		30: $spacer * 10.00,
		31: $spacer * 11.00,
		32: $spacer * 12.00,
		33: $spacer * 13.00,
) !default;
