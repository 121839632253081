.site-head {
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;

	@each $breakpoint, $size in $header-height {
		@include media-breakpoint-up($breakpoint) {
			height: $size;
		}
	}
}

.head-block {
	&--left {
		@each $breakpoint, $height in $header-height {
			@include media-breakpoint-up($breakpoint) {
				height: $height;
			}
		}

		@include media-breakpoint-up(xl) {
			height: auto;
			position: relative;
			z-index: 13;
		}
	}
}

.site-foot {
	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $modal-backdrop-bg;
		opacity: $modal-backdrop-opacity;
		display: none;
	}
}

.box-group {
	position: relative;
	margin: rfsSpacing($box-group-padding) 0;

	&__head {
		position: relative;
	}

	&__head {
		margin-bottom: rfsSpacing($box-group-padding / 1.5);

		&--border {
			padding-bottom: rfsSpacing($box-group-padding / 2);
		}
	}

	&__foot {
		margin-top: rfsSpacing($box-group-padding / 2);
	}

	&__inner {
		padding: rfsSpacing($box-group-padding);

		@include media-breakpoint-down(md) {
			padding: 20px;
		}
	}


	&--bg {
		padding: rfsSpacing($box-group-padding) 0;
	}
}

.page-sidebar {
	&--sticky {
		position: sticky;

		@each $breakpoint, $size in $header-height {
			@include media-breakpoint-up($breakpoint) {
				top: $size + 30;
			}
		}

		@include media-breakpoint-up(xl) {
			top: 30px;
		}
	}
}
